import { colors, colorTokens, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

import { css } from '@emotion/react'
import MabryProMediumUrl from './fonts/mabry-pro-medium.otf?url'
import MabryProUrl from './fonts/mabry-pro.otf?url'
import valueSerifProUrl from './fonts/value-serif-medium.ttf?url'

const valueSerifProFamily = 'ValueSerif-Medium'
const MabryProFamily = 'MabryPro-Regular'
const MabryProMediumFamily = 'MabryPro-Medium'

export const shineSkin: Skin = {
  avatarUrl,
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  mainActivitySelector: {
    borderColor: colorTokens['color-stroke-base'],
    backgroundColor: colorTokens['color-bg-base-normal'],
  },
  horizontalLogo: {
    aspectRatio: 82 / 32,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.black,
    backgroundColor: colors.sky.dark,
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: MabryProFamily,
    fontFamilyUrl: MabryProUrl,
  },
  additionalStylesheets: [MabryProUrl, MabryProMediumUrl, valueSerifProUrl],
  additionalStyles: css`
    @font-face {
      font-family: MabryPro-Regular;
      src: url('${MabryProUrl}') format('opentype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: MabryPro-Medium;
      src: url('${MabryProMediumUrl}') format('opentype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ValueSerif-Medium;
      src: url('${valueSerifProUrl}') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `,
  cssVariables: {
    '--skin-font': MabryProFamily,
    '--skin-font-title': valueSerifProFamily,
    '--skin-font-button': MabryProFamily,
    '--skin-font-subtitle1': MabryProMediumFamily,
    '--skin-font-subtitle2': MabryProMediumFamily,
    '--skin-font-body1': MabryProFamily,
    '--skin-font-weight-title': fontweight.medium,
    '--skin-font-weight-subtitle1': fontweight.medium,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.regular,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': 'var(--Shine_Blue-100)',
    /**
     * Harcoded value to respect Figma definition for Shine's Funnel
     * Wiil be updated as soon as global checks with all Pharaoh color tokens will be completed
     */
    '--skin-blue-200': '#EAEDED',
    '--skin-blue-300': 'var(--Shine_Blue-300)',
    '--skin-blue-400': 'var(--Shine_Blue-400)',
    '--skin-blue-500': 'var(--Shine_Grey-0)',
    '--skin-blue-600': 'var(--Shine_Blue-600)',
    '--skin-blue-700': 'var(--Shine_Blue-700)',
    '--skin-blue-800': 'var(--Shine_Blue-800)',
    '--skin-blue-900': 'var(--Shine_Blue-900)',
    '--skin-gray-100': 'var(--Shine_Gray-200)',
    '--skin-gray-200': 'var(--Shine_Gray-200)',
    '--skin-gray-300': 'var(--Shine_Gray-300)',
    '--skin-gray-400': 'var(--Shine_Gray-400)',
    '--skin-gray-500': 'var(--Shine_Gray-500)',
    '--skin-gray-600': 'var(--Shine_Gray-600)',
    '--skin-gray-700': 'var(--Shine_Gray-700)',
    '--skin-gray-800': 'var(--Shine_Gray-800)',
    '--skin-gray-900': 'var(--Shine_Gray-900)',
    '--skin-green-100': 'var(--Shine_Green-100)',
    '--skin-green-200': 'var(--Shine_Green-200)',
    '--skin-green-300': 'var(--Shine_Green-300)',
    '--skin-green-400': 'var(--Shine_Green-400)',
    '--skin-green-500': 'var(--Shine_Green-500)',
    '--skin-green-600': 'var(--Shine_Green-600)',
    '--skin-green-700': 'var(--Shine_Green-700)',
    '--skin-green-800': 'var(--Shine_Green-800)',
    '--skin-green-900': 'var(--Shine_Green-900)',
    '--skin-yellow-100': 'var(--Shine_Yellow-100)',
    '--skin-yellow-200': 'var(--Shine_Yellow-200)',
    '--skin-yellow-300': 'var(--Shine_Yellow-300)',
    '--skin-yellow-400': 'var(--Shine_Yellow-400)',
    '--skin-yellow-500': 'var(--Shine_Yellow-500)',
    '--skin-yellow-600': 'var(--Shine_Yellow-600)',
    '--skin-yellow-700': 'var(--Shine_Yellow-700)',
    '--skin-yellow-800': 'var(--Shine_Yellow-800)',
    '--skin-yellow-900': 'var(--Shine_Yellow-900)',
    '--skin-orange-100': 'var(--Shine_Orange-100)',
    '--skin-orange-200': 'var(--Shine_Orange-200)',
    '--skin-orange-300': 'var(--Shine_Orange-300)',
    '--skin-orange-400': 'var(--Shine_Orange-400)',
    '--skin-orange-500': 'var(--Shine_Orange-500)',
    '--skin-orange-600': 'var(--Shine_Orange-600)',
    '--skin-orange-700': 'var(--Shine_Orange-700)',
    '--skin-orange-800': 'var(--Shine_Orange-800)',
    '--skin-orange-900': 'var(--Shine_Orange-900)',
    '--skin-red-100': 'var(--Shine_Red-100)',
    '--skin-red-200': 'var(--Shine_Red-200)',
    '--skin-red-300': 'var(--Shine_Red-300)',
    '--skin-red-400': 'var(--Shine_Red-400)',
    '--skin-red-500': 'var(--Shine_Red-500)',
    '--skin-red-600': 'var(--Shine_Red-600)',
    '--skin-red-700': 'var(--Shine_Red-700)',
    '--skin-red-800': 'var(--Shine_Red-800)',
    '--skin-red-900': 'var(--Shine_Red-900)',
    '--skin-white': 'var(--Shine_White)',
    '--skin-black': 'var(--Shine_Black)',
    '--skin-linen': 'var(--Shine_White)',
    '--skin-sky-dark': 'var(--Shine_Grey-light-3)',
    '--skin-sky-light': 'var(--Shine_Yellow-1)',
    '--skin-sky-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Shine_Yellow-0) 0%, var(--Shine_Yellow-0) 100%)',
    '--skin-periwinkle-dark': 'var(--Shine_Yellow-0)',
    '--skin-periwinkle-light': 'var(--Shine_Yellow-0)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Shine_Yellow-0) 0%, var(--Shine_Yellow-0) 100%)',
    '--skin-mindaro-dark': 'var(--Shine_Green-400)',
    '--skin-mindaro-light': 'var(--Shine_Green-200)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Shine_Green-400) 0%, var(--Shine_Green-200) 100%)',
    '--skin-jasmine-dark': 'var(--Shine_Yellow-1)',
    '--skin-jasmine-light': 'var(--Shine_Yellow-3)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Shine_Yellow-1) 0%, var(--Shine_Yellow-3) 100%)',
    '--skin-peach-dark': 'var(--Shine_Black)',
    '--skin-peach-light': 'var(--Shine_White)',
    '--skin-peach-gradient': 'radial-gradient(400% 100% at 0% 100%, var(--Shine_Black) 0%, var(--Shine_White) 100%)',
  },
}
